<template>
  <div class="fill-height py-12 mt-6">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row justify="center">
              <v-col class="text-h5 text-center font-weight-bold">
                Kebijakan Privasi
              </v-col>
            </v-row>
            
            <v-row justify="center">
              <v-col cols="12" md="10" sm="10">
                <v-card flat>
                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    Dasar Privasi ini (bersama-sama dengan Syarat-syarat perkhidmatan kami

                    Ketentuan Layanan dan apa-apa dokumen lain yang dirujuk di dalamnya) menyatakan bagaimana data peribadi yang kami kumpulkan daripada anda, atau yang anda berikan kepada kami, akan digunakan, diproses atau didedahkan oleh kami, termasuk bagaimana maklumat tertentu akan digunakan untuk memperibadikan pengiklanan yang diarahkan kepada anda selaras dengan Akta Perlindungan Data Peribadi 2010 (“Akta”) . Dasar Privasi ini juga menetapkan jenis data peribadi yang kami kumpulkan, bagaimana kami menyimpannya dengan selamat, serta bagaimana anda boleh mengakses atau mengubah data peribadi anda yang disimpan oleh kami. Dasar Privasi ini merangkumi penggunaan Laman Web, serta Aplikasi, dan cara lain yang mungkin anda sambungkan atau berinteraksi dengan kami melalui penggunaan produk dan perkhidmatan kami, yang kemudiannya dirujuk sebagai Perkhidmatan.
                  </v-card-text>

                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    Sila baca perkara berikut dengan teliti untuk memahami polisi dan amalan kami mengenai data peribadi anda dan bagaimana kami akan memperlakukannya.

                    Melainkan jika disekat oleh undang-undang yang terpakai, anda bersetuju bahawa apa-apa dan semua data peribadi yang berhubungan dengan anda yang dikumpulkan oleh kami atau yang disediakan oleh anda dari semasa ke semasa boleh digunakan, diproses dan didedahkan untuk tujuan tersebut dan kepada orang-orang seperti yang ditetapkan dalam Dasar Privasi ini.
                  </v-card-text>

                  <v-card-text class="body-1 font-weight-light line-text-second text-first">
                    Kami boleh meminda Dasar Privasi ini pada bila-bila masa dengan menyiarkan versi yang disemak di Laman Web. Walaupun kami berusaha untuk menghantar notis individu setiap kali polisi dipinda, anda akan dianggap telah mengakui dan bersetuju dengan Dasar Privasi yang dipinda jika anda terus menggunakan Laman Web, Aplikasi atau perkhidmatan kami selepas perubahan tersebut berkuat kuasa. Anda diingatkan untuk menyemak Laman Web ini dari semasa ke semasa untuk maklumat terkini mengenai Dasar Privasi ini
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      MENGUMPUL DATA PRIBADI :
                    </p>

                    <div class="body-1 font-weight-light line-text-second mb-3">
                      Anda boleh memilih untuk melayari Laman Web dan Aplikasi tanpa mendedahkan data pribadi anda. Anda tidak perlu memberikan data pribadi sebagai syarat menggunakan Laman Web atau Applikasi itu, kecuali yang perlu bagi kami untuk menyediakan produk dan perkhidmatan yang anda beli atau akses melalui Laman Web atau Aplikasi kami, balas permintaan anda , atau dalam kes-kes di mana anda menghubungi kami secara langsung.
                    </div>

                    <div class="body-1 font-weight-light line-text-second mb-6">
                      Apabila anda menggunakan Perkhidmatan kami, kami mengumpul pelbagai maklumat dari dan mengenai anda, peranti anda, dan interaksi anda dengan Perkhidmatan. Beberapa maklumat ini mengenal pasti anda secara langsung atau boleh digunakan untuk mengenal pasti anda secara mendalam apabila digabungkan dengan data lain.
                    </div>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Maklumat yang anda berikan secara sukarela. Apabila menggunakan Perkhidmatan kami, anda mungkin diminta memberikan maklumat peribadi tentang diri anda, seperti nama anda, maklumat hubungan, maklumat pembayaran, butiran mengenai rumah atau harta yang anda berminat, maklumat kewangan. Ini boleh berlaku, contohnya,

                          <div>
                            <ol>
                              <li>
                                apabila anda melakukan transaksi dengan kami pada atau berhubung dengan Laman Web atau Aplikasi
                              </li>
                              <li>
                                apabila anda mendaftarkan akun dengan kami di Laman Web atau Aplikasi
                              </li>
                              <li>
                                apabila anda menghubungi khidmat pelanggan atau orang jualan kami melalui e-mel, telefon, atau secara peribadi, untuk menyelesaikan sebarang masalah yang mungkin anda hadapi atau dengan pertanyaan atau permintaan
                              </li>
                              <li>
                                apabila anda melanggan perkhidmatan yang kami sediakan di Laman Web atau Aplikasi, atau untuk pemberitahuan e-mail kami, dan / atau buletin
                              </li>
                              <li>
                                apabila anda bersesuaian dengan profesional hartanah (seperti ejen hartanah atau broker, pemberi pinjaman gadai janji atau pegawai pinjaman, pengurus hartanah, pelabur, bangsawan, atau lain-lain) melalui Perkhidmatan
                              </li>
                              <li>
                                apabila anda melengkapkan borang atau transaksi lain, seperti permintaan maklumat pinjaman atau perumahan sewa dan permohonan semakan latar belakang
                              </li>
                              <li>
                                anda juga boleh memberikan maklumat tentang pihak ketiga melalui Perkhidmatan, contohnya, jika anda berkongsi penyenaraian hartanah dengan penerima melalui e-mail atau sms. Sesetengah maklumat yang anda berikan melalui Perkhidmatan kami dikumpulkan dan diproses oleh pihak ketiga (seperti analisis google) bagi pihak kami
                              </li>
                            </ol>
                          </div>
                        </li>
                        <li>
                          Peranti mudah alih dan maklumat penyemak imbas mudah alih. Anda boleh menyesuaikan tetapan pada peranti mudah alih dan penyemak imbas mudah alih anda mengenai Cookies dan perkongsian maklumat tertentu, seperti model peranti mudah alih anda atau bahasa yang digunakan oleh peranti mudah alih anda, dengan melaraskan tetapan privasi dan keselamatan pada peranti mudah alih anda. Sila rujuk arahan yang disediakan oleh pembekal khidmat mudah alih anda atau pengeluar peranti mudah alih.
                        </li>
                        <li>
                          Data Lokasi. Jika anda mendayakan perkhidmatan lokasi pada peranti mudah alih anda, PropertyIn dengan kebenaran anda boleh mengumpul lokasi peranti anda, yang kami gunakan untuk memberikan anda maklumat dan pengiklanan berasaskan lokasi. Jika anda ingin menyahaktifkan ciri ini, anda boleh melumpuhkan perkhidmatan lokasi pada peranti mudah alih anda.
                        </li>
                        <li>
                          Pengurusan E-mail. Anda boleh menerima e-mail dari PropertyIn untuk pelbagai sebab - contohnya, jika anda mengambil tindakan melalui Perkhidmatan, anda mendaftar untuk laporan tetap, atau anda menyiarkan rumah anda untuk dijual atau sewa dan pembeli menghantar mesej kepada anda. Jika anda mempunyai akaun dengan PropertyIn, anda boleh mengedit pilihan anda melalui tetapan akaun anda. Juga, anda boleh menguruskan penerimaan beberapa jenis komunikasi dengan mengikuti arahan yang terdapat dalam e-mail yang kami hantar kepada anda. Sila ambil perhatian bahawa, walaupun anda melanggan langganan email tertentu, kami masih perlu menghantar e-mail kepada anda dengan maklumat transaksional atau pentadbiran yang penting.
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      TUJUAN PENGGUNAAN :
                    </p>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Data peribadi yang anda berikan boleh digunakan dan diproses oleh kami dalam agregat atau secara individu dan boleh digabungkan dengan maklumat lain untuk tujuan mengurus dan mengendalikan Laman Web dan Aplikasi, skop yang termasuk, tetapi tidak terhad kepada yang berikut:

                          <div>
                            <ol>
                              <li>
                                mentadbir Laman Web dan Aplikasi
                              </li>
                              <li>
                                meningkatkan pengalaman penyemakan imbas anda dengan memperibadikan Laman Web dan Aps
                              </li>
                              <li>
                                membolehkan penggunaan perkhidmatan yang tersedia di Laman Web dan Aplikasi
                              </li>
                              <li>
                                menyediakan kepada anda produk dan perkhidmatan yang anda minta secara khusus
                              </li>
                              <li>
                                bertindak balas terhadap pertanyaan atau permintaan anda menurut e-mel dan / atau penyerahan borang di Laman Web atau Aplikasi
                              </li>
                              <li>
                                bertindak balas dan mengambil tindakan susulan terhadap aduan mengenai penggunaan Laman Web, Aplikasi atau apa-apa produk atau perkhidmatan yang disediakan oleh kami
                              </li>
                              <li>
                                menghantar komunikasi komersial umum (bukan pemasaran)
                              </li>
                              <li>
                                melakukan urusan pentadbiran dan pentadbiran operasi
                              </li>
                              <li>
                                menyediakan pihak ketiga dengan maklumat statistik mengenai pengguna kami. Maklumat statistik sedemikian boleh digunakan untuk memperibadikan pengiklanan di Laman Web atau Aplikasi, tetapi tidak boleh digunakan untuk mengenal pasti mana-mana pengguna individu. Untuk butiran mengenai cara untuk memilih untuk tidak mengikut iklan yang diperibadikan, sila rujuk bahagian 8 Dasar Privasi ini
                              </li>
                            </ol> 
                          </div>
                        </li>
                        <li>
                          Kami tidak akan, tanpa persetujuan tegas anda, mendedahkan data pribadi anda kepada pihak ketiga untuk tujuan pemasaran langsung. Persetujuan untuk pendedahan data peribadi kepada pihak ketiga, untuk tujuan menerima komunikasi pemasaran, diberikan melalui borang pendaftaran kami, sama ada dalam talian atau di atas kertas. Anda boleh menarik balik persetujuan anda dengan menghubungi kontak kami.
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      KERAHASIAN :
                    </p>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Data Peribadi yang dipegang oleh kami akan dirahsiakan menurut Dasar Privasi ini berdasarkan mana-mana undang-undang yang berkuat kuasa yang mungkin dari semasa ke semasa berkuatkuasa.
                        </li>
                        <li>
                          Apa-apa soalan, komen, cadangan atau maklumat selain daripada Data Peribadi yang dihantar atau diposkan ke Laman Web, atau mana-mana bahagiannya oleh Pengguna akan dianggap secara sukarela diberikan kepada kami atas dasar yang tidak sulit dan bukan proprietari.
                        </li>
                        <li>
                          Kami berhak untuk menggunakan, mengeluarkan, mendedahkan, menghantar, menyiarkan, menyiarkan dan / atau menyiarkan di tempat lain maklumat tersebut dengan bebas tanpa rujukan lanjut kepada anda.
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                  
                  <v-card-text class="text-first">
                    <p class="title">
                      KESELAMATAN :
                    </p>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          Kami akan mengambil langkah berjaga-jaga teknikal dan organisasi yang munasabah untuk mengelakkan kehilangan, penyalahgunaan atau pengubahan data peribadi anda.
                        </li>
                        <li>
                          Kami akan menyimpan semua data peribadi yang anda berikan pada pelayan yang selamat dalam format yang disulitkan.
                        </li>
                        <li>
                          Anda bagaimanapun memahami bahawa penghantaran maklumat melalui Internet tidak sepenuhnya selamat. Walaupun kami akan melakukan yang terbaik untuk melindungi data peribadi anda, kami tidak dapat menjamin keselamatan data peribadi anda yang dihantar ke Laman Web atau Aplikasi yang mana sebarang penghantaran tersebut adalah risiko anda sendiri.
                        </li>
                        <li>
                          Di mana kami telah memberikan anda (atau di mana anda telah memilih) kata laluan yang membolehkan anda mengakses bahagian tertentu Laman Web atau Aplikasi, anda bertanggungjawab untuk memastikan kata laluan ini sulit. Kami meminta anda untuk tidak berkongsi kata laluan dengan sesiapa sahaja. Kami TIDAK akan meminta anda untuk kata laluan anda selain daripada ketika anda melog masuk ke Laman Web atau Aplikasi, mengikut mana-mana yang berkenaan.
                        </li>
                        <li>
                          Kami menggesa anda untuk mengambil langkah-langkah untuk memastikan maklumat peribadi anda selamat, seperti memilih kata laluan yang kukuh dan menyimpannya secara peribadi, serta keluar dari akaun pengguna anda, dan menutup penyemak imbas web anda apabila selesai menggunakan perkhidmatan kami pada saham yang dikongsi atau tidak terjamin peranti.
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-card-text class="text-first">
                    <p class="title">
                      DATA PRIBADI :
                    </p>

                    <div class="body-1 font-weight-light line-text-second mb-6">
                      Untuk mengurus dan mengendalikan Laman Web dan Aplikasi, kami boleh mendedahkan data peribadi anda kepada mana-mana ahli kumpulan kami, yang bermaksud gabungan kami dan anak-anak syarikat kami. Kami juga boleh mendedahkan data peribadi anda kepada pihak ketiga seperti penyedia perkhidmatan kami dan penasihat profesional untuk tujuan berikut:
                    </div>

                    <div class="body-1 font-weight-light line-text-second">
                      <ul>
                        <li>
                          mematuhi sebarang keperluan undang-undang; untuk menguatkuasakan atau menggunakan Syarat Perkhidmatan kami ketentuan layanan dan perjanjian lain.
                        </li>
                        <li>
                          untuk melindungi hak, harta dan keselamatan kami, dan hak, harta dan keselamatan afiliasi dan anak syarikat kami, pengguna lain Laman Web atau Aplikasi, dan lain-lain.
                        </li>
                        <li>
                          berkaitan dengan sebarang prosiding undang-undang atau prosiding undang-undang yang berpotensi, dan
                        </li>
                        <li>
                          berkaitan dengan transaksi aset perniagaan (iaitu pembelian, penjualan, pajakan, penggabungan atau penyatuan atau apa-apa pengambilalihan, pelupusan atau pembiayaan lain organisasi atau sebahagian daripada organisasi atau mana-mana perniagaan atau aset sesuatu organisasi) kepada yang mana kita adalah parti atau bakal calon.  
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: 'privacy',
          title: 'Kebijakan Privasi',
          description: 'Kebijakan Privasi',
          image: require('@/assets/logo/logo.png'),
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>