<template>
  <div>
    <v-app-bar 
      app
      elevation="0"
      fixed
      class="header">
      <v-container>
        <v-row justify="center" align="center" class="hidden-md-and-down" v-if="$vuetify.breakpoint.name === 'lg'">
          <v-col cols="2" class="d-flex align-center justify-center">
            <nuxt-link to="/">
              <img 
                alt="propertyin.id"  
                width="150"
                height="auto"
                :src="require('@/assets/logo/logo.png')"/>
            </nuxt-link>
          </v-col>
          <v-col cols="7" class="d-flex align-center justify-end">
            <nuxt-link 
              v-for="(item, index) in menu"
              :key="index"
              :to="item.link">
              <v-card-title class="body-2 menu cursor-pointer text-first hover-link">
                {{ item.label }}
              </v-card-title>
            </nuxt-link>
          </v-col>
          <v-col cols="3" class="d-flex align-center justify-end">
            <v-btn
              v-for="(item, index) in sosmed"
              :key="index"
              class="mx-1"
              rounded
              icon
              small
              elevation="0"
              :href="item.link" target="_blank">
              <v-icon class="header-icon">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" class="hidden-lg-and-up" v-if="$vuetify.breakpoint.name === 'xs'">
          <v-col cols="10">
            <nuxt-link to="/">
              <img 
                alt="propertyin.id"  
                width="150"
                height="auto"
                :src="require('@/assets/logo/logo.png')"/>
            </nuxt-link>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-end">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  elevation="0"
                  class="caption text-capitalize white--text" 
                  color="#8BC541"
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in menu"
                  :key="index"
                  :to="item.link">
                  <v-list-item-title class="color-first">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn 
                      small 
                      color="#8BC541"
                      elevation="0"
                      rounded
                      class="body-2 text-capitalize white--text mt-6 font-weight-bold px-8" 
                      height="35"
                      block
                      to="#contact">
                      MULAI SEKARANG
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    data () {
      return {
        menu: [
          {
            label: "Beranda",
            link: "/"
          },
          {
            label: "Tentang",
            link: "#about"
          },
          {
            label: "Fitur",
            link: "#feature"
          },
          {
            label: "Harga",
            link: "#pricing"
          },
          // {
          //   label: "Testimoni",
          //   link: "#testimonial"
          // },
          {
            label: "Kontak Kami",
            link: "#contact"
          }
        ],
        sosmed: [
          {
            icon: 'mdi-facebook',
            link: 'https://www.facebook.com/people/Property-In/100082096971759/'
          },
          {
            icon: 'mdi-instagram',
            link: 'https://www.instagram.com/propertyin_id/'
          },
          // {
          //   icon: 'mdi-twitter',
          //   link: '#'
          // },
          // {
          //   icon: 'mdi-youtube',
          //   link: '#'
          // }
        ]
      }
    },
    computed: {
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })
      storeScroll()
    },
    methods: {
      logout() {
        Cookie.remove('token')
        Cookie.remove('user')

        window.location = process.env.BASE_URL
      }
    }
  }
</script>

<style scoped>
.header {
  background-color: transparent !important;
}
.header-icon {
  color: #FFFFFF !important;
}
html:not([data-scroll="0"]) .header {
  background-color: #FFFFFF !important;
}
html:not([data-scroll="0"]) .header-icon {
  color: inherit !important;
}
</style>