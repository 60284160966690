<template>
  <div>
    <v-dialog v-model="dialog"
      width="400"
      persistent>
      <v-card 
        color="#f3f3f3"
        class="border-radius">
        <v-card
          flat
          class="border-radius pa-2">
          <v-card-text class="pa-0 text-right">
            <v-btn
              fab
              x-small
              elevation="0"
              class="background-first white--text"
              @click="dialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-card-text>
          <div class="pa-5 pt-0">
            <div class="d-flex align-center mb-5">
              <v-divider color="white"></v-divider>
              <div class="mx-1 font-weight-bold text-second">BERHASIL</div>
              <v-divider color="white"></v-divider>
            </div>
            <div class="d-flex justify-center">
              <div class="sa">
                <div class="sa-success">
                  <div class="sa-success-tip"></div>
                  <div class="sa-success-long"></div>
                  <div class="sa-success-placeholder"></div>
                  <div class="sa-success-fix"></div>
                </div>
              </div>
            </div>
            <div class="text-center body-2 text-second line-text-second mt-4 mb-8">
              Selamat akun anda berhasil terdaftar, silahkan cek email anda untuk melihat informasi akun.
            </div>
            <div class="text-center mt-3">
              <a :href="subdomain">
                <small class="text-second">
                  Sudah punya akun ?
                  <span class="color-first cursor-pointer">
                    Login disini
                  </span>
                </small>
              </a>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>

    <section>
      <v-img 
        alt="propertyin.id"
        width="100%"
        min-height="100vh"
        class="align-center"
        :src="require('@/assets/background/header.png')">
        <v-container :style="$vuetify.breakpoint.name === 'xs' ? 'margin-top: 75px;' : ''">
          <v-row align="center">
            <v-col cols="12" md="6">
              <v-divider class="mb-6" style="width: 10%; border: 3px solid var(--color-primary);"/>
              <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
                MiPRO - Mitra Property
              </p>
              <v-row>
                <v-col cols="12">
                  <p class="title font-weight-light line-text-second text-second">
                    Para pebisnis properti pakai software ini untuk membantu efisiensi operasional perusahaan pengembangan bisnis propertinya.
                  </p>
                </v-col>
              </v-row>
              <v-btn 
                :color="style.primary"
                elevation="0"
                class="body-2 text-capitalize white--text mt-6 font-weight-bold px-8" 
                large
                rounded
                to="#contact">
                MULAI SEKARANG
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-img 
                alt="propertyin.id" 
                width="75%"
                height="auto"
                class="ml-auto align-center mx-auto"
                contain
                :src="require('@/assets/image/mobile.png')">
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </section>

    <section id="about">
      <v-container class="pt-16" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 100px;' : 'margin-bottom: 250px;'">
        <v-row align="center" justify="center">
          <v-col cols="12" md="7" class="text-center">
            <v-divider class="mb-6 mx-auto" style="width: 10%; border: 3px solid var(--color-primary);"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Apa itu MiPRO dan Bagaimana Cara Kerjanya?
            </p>
            <p class="title font-weight-light line-text-second text-second mb-12">
              MiPRO adalah sebuah system software yang memudahkan kita mengelola banyak order dari pembeli produk properti dengan segudang fitur yang sangat memudahkan dan canggih.
            </p>
          </v-col>
          <v-col cols="12" md="10">
            <v-card class="box-shadow border-radius pa-5">
              <v-img 
                alt="propertyin.id" 
                width="100%"
                height="auto"
                class="ml-auto align-center mx-auto"
                contain
                :src="require('@/assets/image/dashboard.png')">
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 100px;' : 'margin-bottom: 250px;'">
        <v-row align="center" justify="center">
          <v-col cols="12" md="7" class="text-center">
            <v-divider class="mb-6 mx-auto" style="width: 10%; border: 3px solid var(--color-primary);"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-16">
              Keuntungan dari MiPRO
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-img 
              alt="propertyin.id" 
              width="75%"
              height="auto"
              class="ml-auto align-center mx-auto"
              contain
              :src="require('@/assets/image/advantage_1.png')">
            </v-img>
          </v-col>
          <v-col cols="12" md="6">
            <p class="text-h5 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Tingkatkan efektifitas pekerjaan marketing
            </p>
            <p class="title font-weight-light text-second line-text-second">
              Tim marketing dapat dengan mudah memasarkan produk properti dan mengelola pesanan dari pembeli dengan lebih cepat.
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <p class="text-h5 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Hemat waktu dan biaya
            </p>
            <p class="title font-weight-light text-second line-text-second">
              MiPRO membantu marketing menjalankan transaksi jual beli properti secara menyeluruh mulai dari booking properti, pembayaran DP, pengurusan KPR, hingga update status pembangunan properti.
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-img 
              alt="propertyin.id" 
              width="75%"
              height="auto"
              class="ml-auto align-center mx-auto"
              contain
              :src="require('@/assets/image/advantage_2.png')">
            </v-img>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-img 
              alt="propertyin.id" 
              width="75%"
              height="auto"
              class="ml-auto align-center mx-auto"
              contain
              :src="require('@/assets/image/advantage_3.png')">
            </v-img>
          </v-col>
          <v-col cols="12" md="6">
            <p class="text-h5 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Kapan dan dimanapun mudah pantau tim marketing
            </p>
            <p class="title font-weight-light text-second line-text-second">
              Respon yang cepat oleh marketing, pelayanan berkas, dan menyediakan informasi spesifikasi produk properti anda dapat dengan mudah anda monitoring.
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <p class="text-h5 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Sistem laporan penjualan yang realtime
            </p>
            <p class="title font-weight-light text-second line-text-second">
              MiPRO akan menyajikan data laporan penjualan secara langsung dan dapat dipantau dengan mudah.
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-img 
              alt="propertyin.id" 
              width="75%"
              height="auto"
              class="ml-auto align-center mx-auto"
              contain
              :src="require('@/assets/image/advantage_4.png')">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="feature">
      <v-container class="py-16" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 100px;' : 'margin-bottom: 250px;'">
        <v-row align="center" justify="center">
          <v-col cols="12" md="7" class="text-center">
            <v-divider class="mb-6 mx-auto" style="width: 10%; border: 3px solid var(--color-primary);"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Fitur Menarik
            </p>
            <p class="title font-weight-light line-text-second text-second mb-12">
              Fitur melimpah yang membantu pelaku bisnis properti
            </p>
          </v-col>
        </v-row>

        <v-row align="end">
          <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? '' : 'text-right'">
            <div>
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'ml-auto align-end mb-3'"
                contain
                :src="require('@/assets/image/features/feature_1.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Realtime Database
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Kebutuhan informasi yang cepat, kapanpun dan dimanapun akan mudah terkases dengan gadget anda
              </p>
            </div>
            <div class="my-6">
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'ml-auto align-end mb-3'"
                contain
                :src="require('@/assets/image/features/feature_2.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Notification new booking
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Pemberitahuan order bisa cepat diterima oleh tim marketing dengan gadget mereka
              </p>
            </div>
            <div>
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'ml-auto align-end mb-3'"
                contain
                :src="require('@/assets/image/features/feature_3.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Notification canceled booking
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Pemberitahuan pembatalan order dapat langsung dilihat oleh marketing
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img 
              alt="propertyin.id" 
              width="91%"
              height="auto"
              class="ml-auto align-center mx-auto"
              contain
              :src="require('@/assets/image/mobile_2.png')">
            </v-img>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'mr-auto align-start mb-3'"
                contain
                :src="require('@/assets/image/features/feature_4.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Live tracking development progress
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Memantau langsung status proses pengembangan property oleh pembeli dan anda
              </p>
            </div>
            <div class="my-6">
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'mr-auto align-start mb-3'"
                contain
                :src="require('@/assets/image/features/feature_1.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Live tracking progress pembayaran uang muka dan KPR
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Status proses pembayaran uang muka dan KPR pun bisa langsung dimonitoring
              </p>
            </div>
            <div>
              <v-img 
                alt="propertyin.id" 
                width="70"
                height="70"
                :class="$vuetify.breakpoint.name === 'xs' ? 'mx-auto mb-3' : 'mr-auto align-start mb-3'"
                contain
                :src="require('@/assets/image/features/feature_4.png')">
              </v-img>
              <p class="title" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Live chat maintenance development
              </p>
              <p class="body-1 font-weight-light line-text-second" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : ''">
                Selama proses pengembangan calon penghuni bisa langsung chat kepada pengembang
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="parallax">
      <v-container :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 100px;' : 'margin-bottom: 250px;'">
        <v-row align="center" justify="center" style="height: 500px;">
          <v-col>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first white--text text-center">
              Segera daftarkan bisnis properti anda sekarang
            </p>
            <v-row
              justify="center">
              <v-col
                class="text-center white--text"
                cols="12" md="7">
                <p class="text-h5 font-weight-light line-text-first">
                  Nikmati layanan MiPRO untuk kemudahan bisnis anda.
                </p>
                <p class="text-h5 font-weight-light line-text-first">
                  Tim MiPRO akan segera menghubungi anda.
                </p>
                <v-btn
                  href="https://wa.me/62811147187" target="_blank"
                  color="white"
                  elevation="0"
                  class="body-2 text-capitalize color-first mt-6 font-weight-bold" 
                  large
                  rounded>
                  <v-icon class="mr-1">mdi-phone-in-talk-outline</v-icon>
                  HUBUNGI KAMI SEKARANG
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
    
    <section id="pricing">
      <v-container class="pt-16" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 75px;' : 'margin-bottom: 200px;'">
        <v-row align="center" justify="center">
          <v-col cols="12" md="7" class="text-center">
            <v-divider class="mb-6 mx-auto" style="width: 10%; border: 3px solid var(--color-primary);"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Biaya Layanan
            </p>
            <p class="title font-weight-light line-text-second text-second">
              Investasi Terbaik untuk Masa Depan Bisnis properti anda di sini
            </p>
            <!-- <p class="body-1 font-weight-light line-text-second text-second mb-12">
              *harga dapat berubah sewaktu waktu
            </p> -->
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="$vuetify.breakpoint.name === 'lg'">
          <v-col cols="12">
            <div class="d-flex align-start justify-center">
              <v-card
                class="mx-1"
                min-width="224"
                height="450"
                flat
                style="border-radius: 10px;"
                v-for="(item, index) in pricing" :key="index">
                <v-img 
                  class="mx-auto pt-6 pb-3"
                  width="100"
                  contain
                  alt="propertyin.id"
                  :src="item.icon">
                </v-img>
                <v-card-text class="text-center text-h5 font-weight-bold color-first pb-0">
                  {{ item.label }}
                </v-card-text>
                <v-card-text class="body-1 text-center pb-6">
                  {{ item.quota }}
                </v-card-text>
                <v-card-text style="background: var(--color-primary);">
                  <!-- <p class="text-h6 white--text text-center font-weight-bold mt-3">
                    {{ item.unit | price }}rb / Unit
                  </p>
                  <v-divider color="white" class="my-6"/> -->
                  <p class="title font-weight-light white--text text-center">
                    Unlimited Akun Pengguna
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
          <v-col cols="12"
            v-for="(item, index) in pricing" :key="index">
            <v-card
              class="box-shadow"
              style="border-radius: 10px;">
              <v-list three-line>
                <v-list-item>
                  <v-list-item-avatar tile size="50">
                    <v-img :src="item.icon"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-h5 font-weight-bold color-first">
                      {{ item.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      {{ item.quota }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card-text style="background: #8BC541;">
                <!-- <p class="body-1 white--text font-weight-bold d-flex align-center mb-0">
                  <v-icon small color="white" class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                  {{ item.unit | price }}rb / Unit
                </p> -->
                <!-- <v-divider color="white" class="my-2"/> -->
                <p class="body-1 font-weight-light white--text d-flex align-center mb-0">
                  <v-icon small color="white" class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                  Unlimited Akun Pengguna
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" align="center">
          <v-col cols="12">
            <!-- <no-ssr>
              <carousel-3d 
                ref="carousel3d"
                :autoplay="false"
                :border="0"
                :space="375"
                :width="350"
                :height="450"
                :count="pricing.length"
                :disable3d="true"
                :display="3"
                @after-slide-change="onAfterSlideChange">
                <slide v-for="(item, index) in pricing" :key="index" :index="index"
                  style="background: none; border-radius: 30px !important;" class="box-shadow">
                  <v-card
                    height="450"
                    class="box-shadow"
                    style="border-radius: 30px;"
                    @click="selected = index">
                    <v-img 
                      class="mx-auto pt-6 pb-3"
                      width="125"
                      contain
                      alt="propertyin.id"
                      :src="item.icon">
                    </v-img>
                    <v-card-text class="text-center text-h4 font-weight-bold color-first pb-0">
                      {{ item.label }}
                    </v-card-text>
                    <v-card-text class="body-1 text-center pb-6">
                      {{ item.quota }}
                    </v-card-text>
                    <v-card-text style="background: #4FC3F7;">
                      <p class="text-h5 white--text text-center font-weight-bold mt-3">
                        {{ item.unit | price }}rb / Unit
                      </p>
                      <v-divider color="white" class="my-6"/>
                      <p class="title font-weight-light white--text text-center">
                        Unlimited Akun Pengguna
                      </p>
                    </v-card-text>
                  </v-card>
                </slide>
              </carousel-3d>
            </no-ssr>

            <div class="text-center my-12"> 
              <v-btn 
                v-for="(item, index) in pricing" 
                :key="index"
                :width="selected === index ? 10 : 13"
                :height="selected === index ? 15 : 13"
                elevation="0"
                x-small
                :rounded="selected === index"
                :fab="selected !== index"
                :color="selected === index ? '#4FC3F7' : '#B2B2B2'"
                class="mx-2"
                @click="goToSlide(index)">
              </v-btn>
            </div> -->

            <div class="text-center my-12"> 
              <v-btn
                href="https://wa.me/62811147187" target="_blank"
                :color="style.primary"
                elevation="0"
                class="body-2 text-capitalize white--text mx-auto font-weight-bold" 
                large
                rounded>
                <v-icon class="mr-1">mdi-phone-in-talk-outline</v-icon>
                HUBUNGI KAMI SEKARANG
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- <section id="testimonial">
      <v-container class="pt-16" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 75px;' : 'margin-bottom: 200px;'">
        <v-row align="center" justify="center">
          <v-col cols="12" md="7" class="text-center">
            <v-divider class="mb-6 mx-auto" style="width: 10%; border: 3px solid #4FC3F7;"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Apa Kata Mereka?
            </p>
            <p class="title font-weight-light line-text-second text-second mb-6">
              Kepuasan anda menggunakan software ini adalah prioritas kami.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4"
            v-for="(item, index) in feedback"
            :key="index">
            <v-card flat class="pa-6 border-radius hover-card">
              <v-card-title class="justify-center mb-3">
                <v-avatar size="100">
                  <v-img
                    contain
                    :src="item.image">
                  </v-img>
                </v-avatar>
              </v-card-title>
              <v-card-text class="body-1 font-weight-light line-text-second text-center" style="min-height: 150px;">
                "{{ item.desc }}"
              </v-card-text>
              <v-card-text class="text-center">
                <p class="title mb-2">
                  {{ item.name }}
                </p>
                <p class="body-1 font-weight-light">
                  {{ item.job }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <section id="contact">
      <v-container class="py-16" :style="$vuetify.breakpoint.name === 'xs' ? 'margin-bottom: 100px;' : 'margin-bottom: 250px;'">
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-divider class="mb-6" style="width: 10%; border: 3px solid var(--color-primary);"/>
            <p class="text-h4 font-weight-bold color-third text-uppercase line-text-first mb-6">
              Hubungi Kami
            </p>
            <p class="title font-weight-bold line-text-second text-second">
              Support
            </p>
            <div class="d-flex align-start">
              <div class="mr-3">
                <v-icon>mdi-map-marker-radius-outline</v-icon>
              </div>
              <div>
                <p class="body-1 mb-2">
                  Bale Bolo Group
                </p>
                <p class="body-1 line-text-second">
                  Ruko Forest Hill Block A9, Citraland BSB City, Pesantren, Kec. Mijen, Kota Semarang, Jawa Tengah, Indonesia
                </p>
              </div>
            </div>
            <div class="d-flex align-start my-3">
              <div class="mr-3">
                <v-icon>mdi-phone-in-talk-outline</v-icon>
              </div>
              <div>
                <p class="body-1 mb-2">
                  +62 811-147-187
                </p>
              </div>
            </div>
            <!-- <div class="d-flex align-start my-3">
              <div class="mr-3">
                <v-icon>mdi-phone-in-talk-outline</v-icon>
              </div>
              <div>
                <p class="body-1 mb-2">
                  +62 857 4539 6401
                </p>
              </div>
            </div> -->
            <div class="d-flex align-start">
              <div class="mr-3">
                <v-icon>mdi-email-outline</v-icon>
              </div>
              <div>
                <p class="body-1 mb-2">
                  miprocenter@gmail.com
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card
              class="box-shadow border-radius pa-12">
              <ValidationObserver ref="observer">
                <v-form>
                  <v-img alt="propertyin.id"  
                    width="200"
                    contain
                    class="mr-auto mb-12"
                    height="auto"
                    :src="require('@/assets/logo/logo.png')">
                  </v-img>
                  <ValidationProvider name="Nama Lengkap" rules="required" v-slot="{ errors }">
                    <v-text-field
                      label="Nama Lengkap"
                      :color="style.primary"
                      prepend-inner-icon="mdi-account-circle-outline"
                      v-model="form.name"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                  
                  <ValidationProvider name="Alamat Email" rules="required|email" v-slot="{ errors }">
                    <v-text-field
                      label="Alamat Email"
                      :color="style.primary"
                      prepend-inner-icon="mdi-email-outline"
                      v-model="form.email"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider name="Nomor Telepon" rules="required|min:10|max:13" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      label="Nomor Telepon"
                      :color="style.primary"
                      prepend-inner-icon="mdi-phone-in-talk-outline"
                      v-model="form.mobilephone" 
                      :counter="13"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                  <div class="mt-12 mb-6">
                    <v-alert 
                      type="error" 
                      text
                      dense
                      prominent
                      v-show="error.message.length > 0"
                      v-html="error.message">
                    </v-alert>
                    <v-btn
                      rounded
                      large
                      :color="style.primary"
                      elevation="0"
                      class="body-2 white--text text-capitalize font-weight-bold"
                      :disabled="process.run"
                      :loading="process.run"
                      @click="save">
                      DAFTAR SEKARANG
                    </v-btn>
                  </div>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        subdomain: process.env.API_MANAGEMENT,
        dialog: false,
        selected: 0,
        pricing: [
          {
            label: 'Starter',
            sublabel: 'Per Proyek',
            quota: 'Kuota 50 Unit',
            price: 6000000,
            unit: 120,
            icon: require('@/assets/image/starter.png')
          },
          {
            label: 'Professional',
            sublabel: 'Per Proyek',
            quota: 'Kuota 120 Unit',
            price: 12000000,
            unit: 100,
            icon: require('@/assets/image/professional.png')
          },
          {
            label: 'Business',
            sublabel: 'Per Proyek',
            quota: 'Kuota 250 Unit',
            price: 20000000,
            unit: 80,
            icon: require('@/assets/image/business.png')
          },
          {
            label: 'Platinum',
            sublabel: 'Per Proyek',
            quota: 'Kuota 500 Unit',
            price: 30000000,
            unit: 60,
            icon: require('@/assets/image/platinum.png')
          },
          {
            label: 'Diamond',
            sublabel: 'Per Proyek',
            quota: 'Kuota 1000 Unit',
            price: 40000000,
            unit: 40,
            icon: require('@/assets/image/diamond.png')
          }
        ],
        feedback: [
          {
            name: 'Firmansyah',
            job: 'Co-founder at Company',
            desc: 'Selama kami menggunakan layanan Propertyin.id, kami merasa lebih terbantu karena tidak perlu repot folowup manual.',
            image: require('@/assets/image/user_1.png')
          },
          {
            name: 'Abdul',
            job: 'Co-founder at Company',
            desc: 'Selama kami menggunakan layanan Propertyin.id, kami merasa bekerja lebih efektif dan efisien.',
            image: require('@/assets/image/user_2.png')
          },
          {
            name: 'Rafi',
            job: 'Co-founder at Company',
            desc: 'Software aplikasi wajib bagi developer properti saat ini. Karena Propertyin.id tools yang sangat bisa diandalkan.',
            image: require('@/assets/image/user_3.png')
          }
        ],
        form: {
          name: '',
          email: '',
          mobilephone: ''
        },
        process: {
          run: false
        },
        error: {
          message: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Beranda',
          description: 'MiPRO',
          image: require('@/assets/logo/logo.png'),
        },
        style: {
          primary: "#8BC541"
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      
    },
    computed: {
      
    },
    mounted () {

    },
    methods: {
      onAfterSlideChange(index) {
        this.selected = index
      },
      goToSlide(index) {
        this.selected = index
        this.$refs.carousel3d.goSlide(index)
      },
      async save() {
        this.error.message = ''
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.process.run = true
          await this.$axios.$post(`register-company`, this.form)
          .then((response) => {
            if (response.status === 200) {
              this.process.run = false
              this.dialog = true

              this.form = {
                name: '',
                email: '',
                mobilephone: ''
              }
            } else {
              this.process.run = false
              this.error.message = response.msg
            }
          })
        }
      }
    }
  }
</script>

<style>
html {
  scroll-behavior: smooth;
}
.parallax {
  background-image: url('../assets/background/parallax.png');
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<style lang="scss">
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
  &-success {
    border-radius: 50%;
    border: 4px solid #8BC541;
    box-sizing: content-box;
    height: 80px;
    padding: 0;
    position: relative;
    background-color: #fff;
    width: 80px;
    &:after, &:before {
      background: #fff;
      content: '';
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }
    &:before {
      border-radius: 40px 0 0 40px;
      width: 26px;
      height: 80px;
      top: -17px;
      left: 5px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }
    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
      animation: rotatePlaceholder 4.25s ease-in;
    }
    &-placeholder {
      border-radius: 50%;
      border: 4px solid rgb(80 195 247 / 25%);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }
    &-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }
    &-tip, &-long {
      background-color: #8BC541;
      border-radius: 2px;
      height: 5px;
      position: absolute;
      z-index: 2;
    }
    &-tip {
      left: 14px;
      top: 46px;
      transform: rotate(45deg);
      width: 25px;
      animation: animateSuccessTip .75s;
    }
    &-long {
      right: 8px;
      top: 38px;
      transform: rotate(-45deg);
      width: 47px;
      animation: animateSuccessLong .75s;
    }
  }
}
@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }
  100%, 12% {
    transform: rotate(-405deg);
  }
}
</style>