<template>
  <v-app>
    <HeadersPublic v-if="!token || $route.name === 'index'"/>
    <v-sheet light :class="$vuetify.breakpoint.name === 'xs' && $route.name !== 'index' ? 'pa-2' : ''" 
      v-if="!token || $route.name === 'index'">
      <nuxt/>
    </v-sheet>
    <v-main light v-else :class="$vuetify.breakpoint.name === 'xs' ? 'pa-2' : ''">
      <nuxt/>
    </v-main>
    <Footers v-if="!token || $route.name === 'index'"/>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="#333333"
        @click="$vuetify.goTo('#app', {duration: 500, offset: 0})">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  import HeadersPublic from "~/components/public/Header.vue"
  import Footers from "~/components/Footer.vue"
  export default {
    data () {
      return {
        fab: false
      }
    },
    components: {
      HeadersPublic,
      Footers
    },
    computed: {
      token () {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      
    },
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > window.innerHeight-100
      }
    }
  }
</script>
