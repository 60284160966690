<template>
  <div>
    <v-img
      width="100%"
      height="aut0"
      class="d-flex align-end"
      :src="require('@/assets/image/footer.png')">
      <v-container>
        <v-row justify="center" align="end" class="d-flex align-center justify-center mb-6">
          <v-col cols="12" sm="3">
            <v-img alt="propertyin.id" v-if="$vuetify.breakpoint.name !== 'xs'"
              width="150"
              contain
              :src="require('@/assets/logo/logo_white.png')">
            </v-img>
          </v-col>
          <v-col cols="12" sm="6">
            <div v-if="$vuetify.breakpoint.name !== 'xs'"
              class="body-2 font-weight-bold text-center white--text">
              Copyright @2020. Powered by Esoda
            </div>
          </v-col>
          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.name === 'xs' ? 'text-center' : 'text-right'">
            <v-btn
              v-for="(item, index) in sosmed"
              :key="index"
              class="mx-1"
              rounded
              icon
              small
              elevation="0"
              :href="item.link" target="_blank">
              <v-icon color="white">
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sosmed: [
          {
            icon: 'mdi-facebook',
            //link: 'https://www.facebook.com/profile.php?id=100075074940664'
            link: 'https://www.facebook.com/people/Property-In/100082096971759/'
          },
          {
            icon: 'mdi-instagram',
            //link: 'https://www.instagram.com/propertyin.id/'
            link: 'https://www.instagram.com/propertyin_id/'

          },
          // {
          //   icon: 'mdi-twitter',
          //   link: '#'
          // },
          // {
          //   icon: 'mdi-youtube',
          //   link: '#'
          // }
        ],
        process: {
          run: false
        },
        success: {
          message: ''
        },
        error: {
          message: ''
        }
      }
    },
    methods: {
    }
  }
</script>
